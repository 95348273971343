.transactions {
  padding: 117px 40px 0 320px;

  @media screen and (max-width: 1580px) {
    padding: 94px 24px 0 246px;
  }

  @media screen and (max-width: 768px) {
    padding: 96px 0 0 0;
  }

  &-content {
    .table-container {
      background-color: #fff;
      width: 100%;
      max-width: 1560px;
      margin: 0 auto;

      table {
        width: 100%;
        font-weight: 500;
        border-spacing: 0 6px;
        border-top: 1px solid #d9d9d9;
        padding: 6px 0 20px 0;
        table-layout: fixed;
      }

      th {
        font-size: 14px;
        text-align: center;
        min-width: 110px;

        &.narrow-cell {
          min-width: 70px;
        }
      }

      tr {
        height: 80px;
        margin-bottom: 6px;
        width: 100%;
      }

      td {
        font-size: 14px;
        text-align: center;
      }

      .grey {
        background: #f9f9f9;
      }

      .table-container-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        // gap: 0.573vw;
        gap: 12px;

        color: #000;
        text-decoration: none;

        // border-left: 0.052vw solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
        // height: 2.708vw;
        height: 52px;

        // padding-left: 2.083vw;
        // padding-left: 40px;

        > img {
          // width: 0.833vw;
          width: 15px;
        }

        &-mobile {
          display: none;

          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }

      .table-header {
        margin-bottom: 22px;
      }
    }
  }
}

.copy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 1.198vw;
  // gap: 23px;

  > button {
    border: none;
    background: none;
    transition: all 300ms ease-in-out;
  }

  @media screen and (max-width: 768px) {
    gap: 6px;
  }
}

.copy {
  height: 0.729vw;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 14px;
    height: 14px;
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 1.198vw;
  gap: 10px;

  @media screen and (max-width: 768px) {
    gap: 6px;
  }
}

.status-indicator {
  // width: 0.417vw;
  // height: 0.417vw;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #5bd548;

  content: ' ';

  @media screen and (max-width: 768px) {
    width: 8px;
    height: 8px;
  }
}

.table-container-buttons {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.history {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.mobile-history {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    width: 100%;
    height: 100%;
    border-top: 1px solid black;
    padding-top: 18px;
  }

  &-block {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 20px;

    @media screen and (max-width: 768px) {
      padding: 24px;
    }

    &-items:last-child {
      align-items: flex-end;
      width: 100%;
      font-size: 16px;

      > :last-child {
        align-self: center;
        justify-self: center;
        width: 100%;
        font-size: 16px;
        background-color: transparent;
      }
    }

    &-items {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 18px;
      font-size: 12px;
      font-weight: 200;

      @media screen and (max-width: 768px) {
        gap: 17px;
        font-weight: 400;

        .button {
            margin-top: 7px;
            padding: 9px 30px
        }
      }

      &-title {
        font-weight: 500;
      }

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  height: 100%;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-visible .tooltip .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.snack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15vw;
  background-color: aqua;
  width: 10vw;
  height: 3vw;
}

.transac {
  padding: 48px 48px 139px 48px;

  @media screen and (max-width: 1580px) {
    padding: 34px 34px;
  }

  @media screen and (max-width: 768px) {
    padding: 31px 20px 124px;
  }
}

.transactions-empty {

  &-content {
    background-color: #F9F9F9;
    text-align: center;
    padding: 40px;

    @media screen and (max-width: 1580px) {
      padding: 47px;
    }

    @media screen and (max-width: 768px) {
      padding: 47px 23px
    }
  }

  &-icon {
    margin-bottom: 17px;

    @media screen and (max-width: 1580px) {
      margin-bottom: 16px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 23px;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &-text {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 25px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &-button {
    padding: 10px 45px;

    @media screen and (max-width: 1580px) {
      padding: 13px 50px;
    }
  }

  table.history {
    padding-bottom: 0;
  }
}

.mobile-history-amount {
  @media screen and (max-width: 768px) {
    font-weight: bold;
    color: #44D42D;
  }
}
