.signup {
  padding-right: 36.4%;
  height: 100vh;

  @media screen and (max-width: 1580px) {
      padding-right: 49.2%;
  }

  @media screen and (max-width: 768px) {
      padding-right: 0;
  }

  .form-error {
      font-size: 14px;
      color: red;
      background-color: rgb(255 0 0 / 15%);
      padding: 14px 24px;
      border-radius: 40px;
      border: 1px solid #e2e2e2;
      margin-bottom: 8px;
      display: block;
  }

  &-row {
      padding: 50px 200px;
      position: relative;

      @media screen and (max-width: 1580px) {
          padding: 63px 57px 63px;
      }

      @media screen and (max-width: 1200px) {
          padding: 50px;
      }

      @media screen and (max-width: 768px) {
          padding: 0;
      }
  }

  &-container {

      @media screen and (max-width: 768px) {
          margin: 0;
          padding: 32px 20px;
      }

      &-form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          // max-width: 379px;
          margin: 0 auto;
          width: 100%;

          @media screen and (max-width: 768px) {
              max-width: 330px;
              height: auto;
              margin: 0 auto;
              display: block;
          }

          &-row {
              max-width: 420px;
              width: 100%;
              margin: 0 auto;
              padding-top: 115px;

              @media screen and (max-width: 1580px) {
                  max-width: 400px;
                  width: 100%;
                  margin: 0 auto;
                  padding-top: 51px;
              }

              @media screen and (max-width: 768px) {
                  padding-top: 3px;
              }
          }

          &-title {
              font-size: 36px;
              margin-bottom: 37px;
              white-space: nowrap;

              @media screen and (max-width: 1580px) {
                  font-size: 32px;
                  margin-bottom: 25px;
              }

              @media screen and (max-width: 768px) {
                  font-size: 24px;
                  margin-bottom: 13px;
              }
          }

          &-continue {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding: 13px 30px;
              font-size: 16px;
              border: 1px solid #e2e2e2;
              border-radius: 50px;
              background-color: #fff;
              margin-bottom: 18px;
              cursor: pointer;

              @media screen and (max-width: 1580px) {
                  padding: 13px 30px;
                  text-align: center;
                  margin-bottom: 16px;
              }

              @media screen and (max-width: 768px) {
                  justify-content: flex-start;
                  padding: 14px 24px;
                  width: 100%;
                  height: 50px;
                  font-size: 14px;
                  border: 1px solid #e2e2e2;
                  border-radius: 14px;
                  background-color: #fff;
                  margin-bottom: 18px;
                  gap: 32px;
                  align-items: center;
              }

              img {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;

                  @media screen and (max-width: 768px) {
                      width: 20px;
                      height: 20px;
                  }
              }
          }

          &-or {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              color: #999;
              font-size: 16px;
              gap: 30px;
              margin-top: 9.6px;
              margin-bottom: 24px;

              @media screen and (max-width: 768px) {
                  font-size: 14px;
                  gap: 33px;
                  margin: 10px 0 24px;
              }

              >hr {
                  width: 100%;
              }
          }

          &-subtitle {
              font-size: 16px;
              margin-bottom: 12px;

              @media screen and (max-width: 768px) {
                  font-size: 14px;
                  margin-bottom: 12px;
                  display: block;
              }
          }

          &-input {
              padding: 14px 24px;
              border-radius: 40px;
              border: 1px solid #e2e2e2;
              font-size: 16px;

              @media screen and (max-width: 1580px) {
                  margin-bottom: 16px;
              }

              @media screen and (max-width: 768px) {
                  width: 100%;
                  height: 50px;
                  padding: 14px 24px;
                  font-size: 14px;
                  border: 1px solid #e2e2e2;
                  border-radius: 40px;

                  &:last-of-type {
                      margin-bottom: 8px;
                  }
              }
          }

          &-remember {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              margin-bottom: 31px;

              @media screen and (max-width: 1580px) {
                  margin-bottom: 22px;
              }

              @media screen and (max-width: 768px) {
                  margin-bottom: 22px;
                  font-size: 14px;
              }

              &-check {
                  display: flex;
                  align-items: center;
                  color: #999;
                  gap: 12px;

                  @media screen and (max-width: 768px) {
                      gap: 12px;
                  }

                  >input {
                      width: 20px;
                      height: 20px;
                      border-radius: 3.5px;
                      border: 1px solid #999;

                      @media screen and (max-width: 768px) {
                          width: 16px;
                          height: 16px;
                          border-radius: 4px;
                          border: 1px solid #000;
                      }
                  }
              }

              &-forgot {
                  color: #f67554;
              }

          }

          &-register {
              font-size: 14px;
              color: #999;
              margin-top: 24px;
              display: block;

              &.__top {
                  display: none
              }

              @media screen and (max-width: 768px) {
                  font-size: 14px;
                  margin-bottom: 14px;
                  display: none;

                  &.__top {
                      display: block;
                      margin-top: 0;
                      margin-bottom: 23px;
                  }
              }
          }

          &-submit {
              border-radius: 50px;
              padding: 16px;
              font-size: 15px;
              margin-bottom: 22px;

              @media screen and (max-width: 768px) {
                  border-radius: 50px;
                  margin-bottom: 24px;
                  display: block;
                  width: 100%;
                  height: 47px;
                  font-size: 14px;
                  padding: 11px;
              }
          }

          &-back {
              height: 56px;
              border: 1px solid #E2E2E2;
              width: 120px;
              border-radius: 50px;
              position: relative;
              margin-bottom: 37px;
              display: block;

              &:after,
              &:before {
                  content: '';
                  position: absolute;
                  left: 29px;
                  width: 14px;
                  height: 2px;
                  background-color: #000;
                  border-radius: 2px;
              }

              &:before {
                  top: 22px;
                  transform: rotate(-45deg);
              }

              &:after {
                  top: 31px;
                  transform: rotate(45deg);
              }

              span {
                  color: #000;
                  font-size: 16px;
                  position: absolute;
                  right: 24px;
                  top: 50%;
                  transform: translateY(-50%);
              }

              @media screen and (max-width: 768px) {
                  height: 37px;
                  border: 1px solid #E2E2E2;
                  width: 100px;
                  border-radius: 6px;
                  position: relative;
                  margin-bottom: 18px;
                  display: block;
                  margin-top: 0;

                  &:after,
                  &:before {
                      left: 24px;
                      width: 8px;
                  }

                  &:before {
                      top: 14px;
                  }

                  &:after {
                      top: 20px;
                  }

                  span {
                      font-size: 13px
                  }
              }
          }

          &-info {
              background: #fbf2f0;
              border-radius: 10px;
              display: flex;
              font-size: 14px;
              gap: 14px;
              margin-bottom: 36px;
              padding: 14px 20px;
              align-items: center;
              line-height: 1.1;
              font-weight: 400;

              @media screen and (max-width: 768px) {
                  font-size: 11px;
                  margin-bottom: 25px;
                  padding: 14px;
                  line-height: 1.25;
                  letter-spacing: 0.45px;
                  display: flex;
                  align-content: center;
                  justify-content: space-between;
              }
          }

          &-helper {
              color: #999;
              font-size: 14px;
              margin-bottom: 14px;

              @media screen and (max-width: 768px) {
                  font-size: 11px;
                  margin-bottom: 14px;
                  display: block;
              }
          }

          &-terms {
              color: #999;
              font-size: 14px;
              line-height: 1.4;
              margin-bottom: 34px;

              @media screen and (max-width: 768px) {
                  font-size: 13px;
                  line-height: 1.4;
                  margin: 13px 0 23px 0;
                  display: block;
                  letter-spacing: -0.5px;
              }
          }
      }

      a {
          color: #f67554;
          text-decoration: none;
          cursor: pointer;
      }

      &-language {
          font-size: 16px;
          font-weight: 700;
          color: #999;

          @media screen and (max-width: 768px) {
              font-size: 14px;

              justify-self: flex-end;
          }
      }
  }

  &-filler {
      background-image: var(--2, linear-gradient(135deg, #e74720 0%, #f67555 100%));
      background-size: cover;
      background-color: #e74720;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 36.4%;

      &-lines {
          width: 100%;
          height: 100%;

          object-fit: cover;
      }

      @media screen and (max-width: 1580px) {
          width: 49.2%;
      }

      @media screen and (max-width: 768px) {
          display: none;
      }
  }

  &-logo-row {
      position: relative;
      max-width: 820px;
      width: 100%;
      margin: 0 auto;

      @media screen and (max-width: 1580px) {
          max-width: 445px;
      }
  }
}


.form-message {
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 20px;

  &.form-message-success {
      background-color: #b2f0b2;
      color: #032903;
  }
}

.form-group {
  margin-bottom: 16px;
}
