@import '../../styles/mixins';

.dashboard {
  display: flex;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }

  &-content {
    display: grid;
    grid-template-columns: 71.3% 28.3%;
    width: 100%;
    margin: 96px 20px 0 248px;

    @media screen and (max-width: 1300px) {
      grid-template-columns: 98%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      grid-template-columns: 100%;
      margin: 96px 0 0 0;
      gap: 24px;
    }

    &-aside {
      padding-left: 16px;

      @media screen and (max-width: 1300px) {
        padding-left: 0;
      }
    }
  }
}

.model-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 8px;

  > h3 {
    color: black;
    font-size: calc(16px - 1 * (100rem - 320px) / (1900 - 320));
    line-height: 12px;
  }
}

.menu-mobile {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  margin: 72px 20px 0;
  gap: 14px;
  font-size: 16px;

  hr {
    border: none;
    border-top: 1px solid #e2e2e2;
  }

  h3 {
    opacity: 0.66;
    font-size: 16px;
    margin: 0;
  }

  > * {
    width: 100%;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;

    > * {
      width: 100%;
    }
  }

  &-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    > ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 14px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        a,
        a:visited {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 18px;
          height: 46px;
          width: calc(100% - 52px);
          padding: 0 26px;
          font-size: 16px;

          img {
            width: 22px;
          }

          color: #000;
          text-decoration: none;
        }
      }

      > li {
        width: 100%;
      }

      > li:hover {
        background-color: #fef1ee;
        border-radius: 40px;

        > a {
          color: #e74720;
        }
      }
    }
  }

  &-profile {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      margin-bottom: 20px;
    }

    .profile-list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .profile-list-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 18px;
        height: 54px;
        width: calc(100% - 52px);
        padding: 0 26px;

        img {
          width: 22px;
        }

        color: #000;
        text-decoration: none;
      }

      .profile-list-item:hover {
        background-color: #fef1ee;
        border-radius: 40px;

        > span {
          color: #e74720;
        }
      }
    }
  }

  &-language {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10rem;

    > .button {
      flex-grow: 2;
    }
  }
}

.balance-empty-bar {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr;
  }

  &-currencies {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
}

.__dashboard_bottom {
  @media screen and (max-width: 1300px) and (min-width: 768px) {
    padding-bottom: 16px;
  }
}

.Assets {
  padding: 2rem 1.25rem;

  @include media-breakpoint(lg) {
    padding: 2rem;
  }

  & img {
    width: 40px;
    height: 40px;
  }
}

.Asset {
  margin-bottom: 6px;
  padding: 0.75rem 0.625rem;

  &__wrapper {
    display: flex;

    @include media-breakpoint(lg) {
      display: grid;
      grid-template-columns: 265px 5fr 1fr;
    }
  }

  &__values {
    display: none;

    @include media-breakpoint(lg) {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @include media-breakpoint(lg) {
    padding: 0.75rem 1.5rem;
  }

  &__title {
    margin-bottom: 0.25rem;

    @include media-breakpoint(lg) {
      font-size: 1.125rem;
      white-space: nowrap;
    }
  }

  &__address {
    width: auto;

    @include media-breakpoint(lg) {
      max-width: 200px;
    }
  }

  &__address {
    width: auto;
    @include media-breakpoint(lg) {
      max-width: 200px;
    }
  }

  &__externalAddressWrapper {
    width: 60%;

    @include media-breakpoint(lg) {
      width: 100%;
    }
  }

  &__addressWrapper {
    width: 70%;

    @include media-breakpoint(lg) {
      width: 100%;
    }
  }

  &__actions {
    width: 30%;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint(lg) {
      border-left: 1px solid var(--gray-300);
      display: grid !important;
      grid-template-columns: 2fr 0.5fr;
      width: 100%;
    }
  }
  &__dropdown {
    top: 45px;
    min-width: 128px;
    background: #fff;
    right: 0;
    z-index: 999;
    border-radius: 1rem;

    &_menu li {
      & a {
        display: block;
        padding: 0.875rem 1rem;
        cursor: pointer;
      }

      &:hover {
        background-color: var(--gray-200);
      }

      &:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}

.ExternalAsset__actions {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 40%;

  & .button {
    padding: 13px 20px;
  }

  @include media-breakpoint(lg) {
    display: flex;
    justify-content: center;
    width: 100%;

    & .button {
      padding: 13px 30px;
    }
  }
}

.space-between {
  justify-content: space-between;
}
