.suggestion-container {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;

  @media screen and (max-width: 1300px) {
    grid-column-start: 1;
    grid-row-start: 4;
  }

  @media screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-row-start: 4;
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    @media screen and (max-width: 1300px) {
      flex-direction: initial;
      align-items: initial;
    }

    @media screen and (max-width: 768px) {
      gap: 24px;
    }

    @media screen and (max-width: 580px) {
      flex-direction: column;
    }

    .__deposit {
      padding-top: 23px;

      .suggestions-element-title {
        margin-bottom: 1rem;
      }
    }

    #exchange {
      padding-top: 49px;

      @media screen and (max-width: 768px) {
        padding-top: 61px;
        padding-bottom: 32px;

        .button {
          margin-top: 0;
          // padding: 9px 48px;
          padding: 13px 30px;
        }
      }
    }
  }
}

.suggestions-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding: 40px;

  // gap: 24px;
  @media screen and (max-width: 1580px) {
    padding: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 16px;
    padding: 40px 20px;

    &.__deposit {
      justify-content: center;

      .suggestions-element-buttons {
        display: flex;
        gap: 20px;
        justify-content: flex-start;

        .button {
          padding: 13px 30px;
        }
      }
    }
  }

  >img {
    width: 120px;
    height: 120px;

    @media screen and (max-width: 768px) {
      width: 116px;
      height: 116px;
      display: block;
      margin-bottom: 15px;
    }
  }

  #container {
    // margin-top: 14px;
  }

  .button {
    font-size: 14px;
    min-width: 100px;
    // margin-top: 24px;
    padding: 13px 20px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }
}

.suggestions-element-title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  @media screen and (max-width: 768px) {
    // font-size: 16px;
    // line-height: 18px;
    display: block;
  }
}

.suggestions-element-helper {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    // line-height: 14px;
  }
}

.suggestions-element-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  gap: 12px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}