.Spinner {
  position: relative;

  &-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-color: transparent #F67554 #F67554;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.sm {
    width: 40px;
    height: 40px;

    .Spinner__circle {
      border-width: 3px;
    }
  }

  &.md {
    width: 70px;
    height: 70px;
  }

  &.lg {
    width: 100px;
    height: 100px;

    .circle {
      border-width: 5px;
    }
  }
}
