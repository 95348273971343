* {
  box-sizing: border-box;
}

html {
  font-family: var(--font-family);
  font-size: 16px;
  // overflow-x: hidden;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  color: #000;
  background-color: #f7f7f7;
}

.coinstorm-logo {
  width: 190px;

  &-sm {
    display: none
  }

  @include media-breakpoint(null, md) {
    display: none;

    &-sm {
      display: block;
      margin: 0 auto 30px auto;
      width: 60px;
    }
  }
}

textarea:focus, input:focus{
  outline: none;
  border: 1px solid var(--orange);
}

.blur-disabled {
  filter: blur(2px);
}

hr {
  border-top: 1px solid var(--gray-300);
  border-bottom: 0;
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.btn-reset {
  padding: 0;
  margin: 0px;
  background: none;
  border: 0;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.MuiModal-root {
  z-index: 5 !important;
}

.text-center {
  text-align: center;
}