.assets-mobile-type-row {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    display: flex;
  }
  > div {
    width: 33.33%;
  }
}

.assets-mobile-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 0 20px;
  gap: 5px;
  background-color: #fff;
  cursor: pointer;
  color: #000;
  text-decoration: none;

  &:hover,
  &.__active {
    background-color: #fef1ee;
    color: #e74720;
  }

  img {
    width: 26px;
    height: 16px;
  }
}
