.modal {
  border-radius: 14px;
  min-width: 440px;
  min-height: 374px;
  max-height: 550px;
  overflow-y: auto;
  justify-self: center;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  z-index: 1310;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);

  @media screen and (max-width: 768px) {
    min-width: 95%;
    min-height: 280px;
    border-radius: 14px;
  }

  .button {
    font-size: 16px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;

    font-size: 14px;

    background: #fef1ee;
    border-radius: 14px 14px 0 0;

    @media screen and (max-width: 768px) {
      padding: 12px 20px;

      &__title {
        font-size: 12px;
      }
    }

    &__close {
      width: 24px;
      height: 24px !important;
      background-color: #fff !important;
      border-radius: 50%;
    }
  }

  &-content {
    padding: 32px 40px;
    display: flex;
    flex-direction: column;

    gap: 40px;

    background-color: #fff;

    border-radius: 0 0 14px 14px;

    @media screen and (max-width: 768px) {
      padding: 20px;

      gap: 32px;

      border-radius: 0 0 14px 14px;
    }

    &-field {
      display: flex;
      flex-direction: column;
      gap: 14px;

      @media screen and (max-width: 768px) {
        gap: 10px;
      }

      &__label {
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &__text {
        font-size: 0.729vw;
        font-size: 14px;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &-toggler {
        display: flex;
        padding: 4px 3px;
        // flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        border-radius: 40px;
        border: 1px solid #e2e2e2;
        background: #fff;

        &__element {
          display: flex;
          // width: 155px;
          width: 100%;
          height: 40px;
          padding: 14px 32px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 24px;
          background: #fff;

          cursor: pointer;
        }

        &__element.active {
          border-radius: 24px;
          background: #fef1ee;
        }
      }

      .input-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &__input {
        font-size: 14px;
        font-weight: 400;

        padding: 14px 24px;

        border-radius: 14px;
        border: 1px solid #e2e2e2;

        width: 100%;

        @media screen and (max-width: 768px) {
          font-size: 12px;

          padding: 14px 20px;
        }

        &-icon {
          width: 18px;
          height: 18px;

          position: absolute;

          float: right;

          margin-right: 24px;

          cursor: pointer;

          @media screen and (max-width: 768px) {
            width: 14px;
            height: 14px;

            margin-right: 20px;
          }
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 14px;

        @media screen and (max-width: 768px) {
          gap: 8px;
        }

        &-item {
          display: flex;
          align-items: center;
          gap: 14px;

          padding: 10px 20px;

          cursor: pointer;

          @media screen and (max-width: 768px) {
            gap: 8px;

            padding: 10px 20px;
          }

          &:hover {
            background-color: #fff8f6;
            border-radius: 10px;

            @media screen and (max-width: 768px) {
              border-radius: 10px;
            }
          }

          &__img {
            width: 36px;
            height: 36px;

            @media screen and (max-width: 768px) {
              width: 28px;
              height: 28px;
            }
          }

          &__name {
            font-size: 16px;

            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }

          &__ticker {
            font-size: 16px;
            color: #999;

            @media screen {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

span.error {
  color: #e74720;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  gap: 8px;
}

input.error {
  border-color: #e74720;
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  position: fixed;
  background-color: black;
  opacity: 0.6;
  z-index: 1300;
}
