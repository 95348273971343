.title {
    font-size: 20px;
}

.balance {
    margin-bottom: 10px;

    .currency-symbol {
        font-size: 20px;
        margin-right: 11px;
    }

    .balance-value {
        font-size: 32px;
    }
}

.helper {
    font-size: 14px;
}
