@import "../../styles/mixins";

.Notifications {
  top: 75px;
  right: 0;
  padding: 0 0.5rem;

  @include media-breakpoint(md) {
    top: 96px;
    right: 5%;
    padding: 0;
  }
}

.Notification {
  padding: 0.75rem 1rem;
  width: calc(100vw - 1rem);
  border-radius: 1rem;

  @include media-breakpoint(md) {
    width: 400px;
  }

  &__closeBtn {
    max-height: 2rem;
    transition: all ease-in-out 300ms;
    
    &:hover {
      background-color: #fff;
      border-radius: 0.5rem;
    }
  }
  
  &.success {
    border: 1px solid var(--green-200);
    background-color: var(--green-100);
  }

  &.warning {
    border: 1px solid var(--warn);
    background-color: #FFF9F0;
  }

  &.error {
    border: 1px solid #EF9783;
    background-color: #FFF4F1;
  }

  &.info {
    border: 1px solid var(--info);
    background-color: #DBECFF;
  }
}