.card-currency {

    &-footer {
        & > button, & > a {
            width: 100%;
        }
    }

    .currency-icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .currency-info {
        &-name {
            margin-bottom: 3px;
        }

        &-symbol {
            text-transform: uppercase;
            color: #AFAFAF
        }
    }

    .price-container {
      display: flex;
      align-items: center;
    }
}
