.send {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 280px;

  @media screen and (max-width: 1580px) {
    padding-left: 223px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }

  &-container {
    width: 600px;
    background-color: #fff;
    @media screen and (min-width: 769px) {
      margin: 120px auto;
      padding: 26px 56px 64px;
    }
    @media screen and (max-width: 768px) {
      width: 96%;
      margin: 96px 0 0 0;
      height: 1155px;
      padding: 24px 10px 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-buttons {
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid #e2e2e2;
      border-radius: 24px;
      padding: 4px;
      margin-bottom: 64px;
      @media screen and (max-width: 768px) {
        max-width: 350px;
        padding: 4px;
        border-radius: 32px;
        border: 1px solid #e2e2e2;
        margin-bottom: 22px;
        width: 100%;
      }
      > * {
        width: 100%;
        border-radius: 24px;
        border: none;
        font-size: 16px;
        @media screen and (max-width: 768px) {
          border-radius: 24px;
        }
      }
    }

    &-balance {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 14px;
      margin-bottom: 24px;

      @media screen and (max-width: 768px) {
        gap: 10px;
        margin-bottom: 15px;

        &.__buy {
          margin-bottom: 24px;
        }
      }

      &-value {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-right: 32%;
        &-input {
          border: none;
          // min-width: 100px;
          width: 100%;
          font-size: 64px;
          text-align: right;
          &:focus-visible,
          &:focus {
            border: none;
            outline: none;
          }
          @media screen and (max-width: 768px) {
            font-size: 45px;
            gap: 10px;
            width: 100px;
            font-weight: bold;
          }
        }
        @media screen and (max-width: 768px) {
          font-size: 40px;
          gap: 10px;
          margin-right: 0;
        }
        &-helper {
          font-size: 20px;
          color: #999;
          align-self: flex-start;
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
        }
      }
      &-helper {
        font-size: 16px;
        color: #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        font-weight: 400;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          gap: 8px;
        }
        #max {
          font-size: 14px;
          font-weight: 300px;
        }
        > * {
          display: flex;
          align-items: center;
          gap: 8px;
          @media screen and (max-width: 768px) {
            gap: 8px;
          }
          > img {
            width: 14px;
            height: 14px;
            @media screen and (max-width: 768px) {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }

    &-currency {
      width: 100%;

      @media screen and (max-width: 768px) {
        margin-bottom: 18px;
        margin-top: 0;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
      }

      &-title {
        font-size: 14px;
        color: #999;

        @media screen and (max-width: 768px) {
          font-size: 14px;
          padding-left: 15px;
        }
      }

      &-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e2e2e2;
        border-radius: 40px;
        padding: 16px 26px;
        margin-top: 12px;
        
        cursor: pointer;
        @media screen and (max-width: 768px) {
          margin-top: 6px;
          padding: 16px 18px;
          border-radius: 40px;
          border: 1px solid #e2e2e2;
        }
        &-element {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 14px;
          img {
            width: 24px;
            height: 24px;
          }
          &-select {
            // display: inline-block;
            // height: 23px;
            height: 14px;
            &__img {
              width: 14px !important;
              height: 14px !important;
            }
          }
          @media screen and (max-width: 768px) {
            font-size: 16px;
            gap: 8px;
            img {
              width: 20px;
              height: 20px;
            }
            #currency {
              display: none;
            }
          }

          &-helper {
            color: #999;

            @media screen and (max-width: 768px) {
              padding-right: 40px;
              font-size: 14px;
            }
          }
        }
      }

      &-button {
        width: 100%;
        border-radius: 2.604vw;
      }
    }

    &-using {
      width: 100%;
      // margin-bottom: 1.667vw;
      margin-bottom: 24px;
      @media screen and (max-width: 768px) {
        margin-bottom: 32px;
        // margin-top: 24px;
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
      }
      &-title {
        font-size: 14px;
        color: #999;

        @media screen and (max-width: 768px) {
          font-size: 14px;
          padding-left: 15px;
        }
      }
      &-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0.052vw solid #e2e2e2;
        border-radius: 2.083vw;
        // padding: 0.833vw 1.354vw;
        margin-bottom: 0.521vw;
        margin-top: 0.625vw;
        @media screen and (max-width: 768px) {
          // width: 278px;
          flex-wrap: wrap;
          margin-top: 10px;
          justify-content: center;
        }
        &-element {
          display: flex;
          align-items: center;
          gap: 0.625vw;
          font-size: 0.729vw;
          padding: 0.729vw 1.667vw;
          cursor: pointer;
          &.active {
            border-radius: 24px;
            background: #fef1ee;
          }
          @media screen and (max-width: 768px) {
            padding: 14px 32px;
            width: 136px;
            height: 40px;
            justify-content: center;
            font-size: 12px;
            &-name {
              justify-self: center;
              align-self: center;
            }
          }
          &:hover {
            border-radius: 24px;
            background: #fef1ee;
          }
          &-helper {
            color: #999;
          }
        }
      }
      &-input {
        width: 100%;
        border-radius: 40px;
        border: 1px solid #e2e2e2;
        background-color: #fff;
        padding: 20px 26px;
        font-size: 14px;
        color: #999;
        margin-top: 12px;
        margin-bottom: 24px;
        @media screen and (max-width: 768px) {
          height: 56px;
          font-size: 16px;
        }
      }
      &-textarea {
        width: 100%;
        height: 6.458vw;
        padding: 1.042vw 1.354vw;
        color: #999;
        // margin-bottom: 1.25vw;
        border-radius: 1.458vw;
        border: 0.052vw solid #e2e2e2;
        background-color: #fff;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 0.729vw;
        @media screen and (max-width: 768px) {
          margin-top: 12px;
          font-size: 12px;
          height: 86px;
        }
      }
    }

    &-speed {
      width: 24.479vw;
      height: 10.885vw;
      display: flex;
      flex-direction: column;
      gap: 0.208vw;
      @media screen and (max-width: 768px) {
        width: 280px;
        height: auto;
      }
      &-blocks {
        height: 5.208vw;
        display: flex;
        align-items: center;
        gap: 0.208vw;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          height: auto;
        }
        &-element {
          display: flex;
          flex-direction: column;
          border-radius: 0.313vw;
          // border: 0.104vw solid #f67554;
          border: 0.104vw solid #e2e2e2;
          padding: 0.729vw 1.042vw;
          gap: 0.417vw;
          width: 8.021vw;
          cursor: pointer;
          &:hover,
          &.active {
            border: 0.104vw solid #f67554;
          }
          @media screen and (max-width: 768px) {
            width: 280px;
            height: 70px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 6px;
          }
          &-type {
            display: flex;
            align-items: center;
            gap: 0.208vw;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &-title {
            display: flex;
            align-items: center;
            font-size: 0.833vw;
            gap: 0.417vw;
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
            > img {
              width: 0.729vw;
              height: 0.729vw;
              @media screen and (max-width: 768px) {
                width: 14px;
                height: 14px;
              }
            }
          }
          &-helper {
            font-size: 0.729vw;
            display: flex;
            flex-direction: column;
            color: #999;
            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
      &-custom {
        padding: 0.729vw 1.042vw;
        width: 24.479vw;
        height: 5.469vw;
        border-radius: 0.313vw;
        border: 0.052vw solid #e2e2e2;
        display: flex;
        flex-direction: column;
        gap: 0.729vw;
        @media screen and (max-width: 768px) {
          width: 280px;
          height: 106px;
          padding: 14px 20px;
          border-radius: 6px;
        }
        &-title {
          font-size: 0.833vw;
          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
        &-slider {
          > span {
            font-size: 0.729vw;
            color: #999;
            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    &-fees {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 24px;

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        gap: 6px;
        margin-top: 24px;
        margin-bottom: 24px;
      }

      &-element {
        display: flex;
        align-items: center;
        gap: 14px;
        height: 64px;
        font-size: 14px;
        padding: 16px 20px;
        background: #f9f9f9;
        @media screen and (max-width: 768px) {
          height: 48px;
          justify-content: space-between;
          font-size: 10px;
          padding: 0px 20px;
        }
        &-block {
          min-width: 148px;
          display: flex;
          align-items: center;
          gap: 14px;

          img {
            width: 16px;
            height: 16px;
          }

          &-title {
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            gap: 8px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }

        &-helper {
          color: #999;

          @media screen and (max-width: 768px) {
            width: 100%;
            font-size: 15px;
            text-align: right;
            color: black;
          }
        }
      }
    }

    &-submit {
      width: 100%;
      border-radius: 50px;
      font-size: 16px;
      @media screen and (max-width: 768px) {
        width: 280px;
      }
    }

    &-submit.disabled {
      width: 100%;
      border-radius: 50px;
      background-color: #999;
      cursor: not-allowed;
      font-size: 16px;
      @media screen and (max-width: 768px) {
        // width: 280px;
        width: 350px;
      }
    }

    &-qr {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-currency {
        display: flex;
        align-items: center;
        gap: 24px;
        font-size: 14px;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
        &-title {
        }
        &-coin {
          display: flex;
          align-items: center;
          gap: 8px;
          > img {
            width: 24px;
            height: 24px;
            @media screen and (max-width: 768px) {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      &-code {
        // width: 220px;
        // height: 220px;
        width: 190px;
        height: 190px;
        margin: 30px 0;
        @media screen and (max-width: 768px) {
          width: 104px;
          height: 104px;
        }
      }
      &-helper {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #999;

        > button {
          border: none;
          background: none;
          transition: all 300ms ease-in-out;
        }

        @media screen and (max-width: 768px) {
          font-size: 12px;
          color: #f67554;
        }
        > img {
          width: 14px;
          height: 14px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    &-info {
      margin: 24px 0 0 0;
      border-radius: 50px;
      border: 1px solid #6cf412;
      background-color: #aafa75;

      padding: 24px;

      font-size: 14px;

      text-align: center;

      @media screen and (max-width: 768px) {
        max-width: 350px;
      }
    }
  }
}
