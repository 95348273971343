.Payment {
  & .send-container {
    border-radius: 12px;
  }

  &__header, &__body {    
    position: relative;
    border-bottom: 1px dashed #DCDEE0; 
  }

  &__header:before, &__header:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f7f7f7;
    display: block;
    border-radius: 50%;
    bottom: -10px;
  }

  &__header:before {
    left: -32px;
  }

  &__header:after {
    right: -32px;
  }

  &__money-container {
    text-transform: uppercase;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &:nth-child(odd) {
      background-color: var(--gray-100);
    }

    & a {
      margin-top: 0.5rem;
    }
  }

  & .btn-reset {
    height: 20px;
  }
}