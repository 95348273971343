@import './variables';

@mixin media-breakpoint($min-width: null, $max-width: null) {
  $min-value: if($min-width != null, map-get($breakpoints, $min-width), null);
  $max-value: if($max-width != null, map-get($breakpoints, $max-width), null);

  @if $min-value and $max-value {
    @media (min-width: #{$min-value}) and (max-width: #{$max-value}) {
      @content;
    }
  }
  @else if $min-value {
    @media (min-width: #{$min-value}) {
      @content;
    }
  }
  @else if $max-value {
    @media (max-width: #{$max-value}) {
      @content;
    }
  }
}
