.button {
  border: none;
  padding: 13px 30px;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
  color: black;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  font-size: 0.875rem;
  // min-height: 48px;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  @media screen and (max-width: 768px) {
    padding: 13px 30px;
    border-radius: 8px;
  }

  &-rounded {
    border-radius: 50px;
  }
}

.orange {
  color: #fff;
  background-color: #f67554;
}

.white {
  background-color: #fff;
  border: 1px solid #e2e2e2;
}

.black {
  border: 1px solid black;
}
