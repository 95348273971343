.balance-container {
  background-color: #fff;
  grid-column-start: 1;
  overflow: hidden;
  width: 100%;
  padding: 48px 48px 64px 48px;

  @media screen and (max-width: 1580px) {
    padding: 32px 32px 64px 32px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 10px 64px 10px;
  }

  .balance {
    height: 289px;
    max-width: 1084px;
    display: grid;
    grid-template-columns: 15.17% 80%;
    grid-template-rows: 100%;
    width: 100%;
    gap: 62px;

    @media screen and (max-width: 1580px) {
      height: 275px;
    }

    @media screen and (max-width: 1000px) {
      grid-template-columns: 20% 75%;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-template-rows: 30% 70%;
      height: auto;
      gap: 0;
    }

    .balance-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @media screen and (max-width: 768px) {
        height: fit-content;
        margin: 0 0 38px 0;
        max-width: none;

        padding: 0 10px;
      }

      .balance-info-buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        margin-top: 26px;

        .button {
          padding: 13px 30px;
          font-size: 16px;

          // @media screen and (max-width: 768px) {
          //     padding: 8px 11px;
          //     font-size: 16px;
          // }
        }

        @media screen and (max-width: 1580px) {
          margin-top: 24px;
        }

        @media screen and (max-width: 768px) {
          max-width: 116px;
          margin-right: 0;
          // margin-top: 44px;
          gap: 8px;
        }
      }
      .balance-info-block {
        display: flex;
        flex-direction: column;
        height: 100%;

        .balance-info-block-val {
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 768px) {
            gap: 10px;
          }
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin: 0;
          padding: 0;
        }
      }
      .balance-info-value {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 20px;
        @media screen and (max-width: 1580px) {
          font-size: 32px;
        }
        @media screen and (max-width: 768px) {
          font-size: 40px;
          line-height: 40px;
          gap: 12px;
        }
        .dollar-sign {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: #999999;
          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
      }
      .balance-info-title {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        text-align: left;
        width: 100%;
        margin-bottom: 62px;
        @media screen and (max-width: 1580px) {
          font-size: 20px;
          margin-bottom: 66px;
        }
        @media screen and (max-width: 768px) {
          margin: 0 0 16px 0;
          font-size: 20px;
          line-height: 24px;
        }
      }
      .balance-info-helper {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-top: 10px;
        color: #999999;
        @media screen and (max-width: 1580px) {
          margin-top: 8px;
        }
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 14px;

          margin-top: 0;
        }
      }
    }

    .balance-chart {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
      flex-grow: 2;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 285px;
        margin-left: 0;
        margin-bottom: 0;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        font-size: 12px;
        line-height: 14px;
        margin-top: 33px;

        // padding: 0 10px;
      }
    }
  }
}

.chart-empty-state {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -57%);
  background: var(--gray-100);
  padding: 1rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 8px;
}