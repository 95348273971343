:root {
  --orange: #F67554;
  --red: #DB1E36;
  --warn: #FDCD7F;
  --info: #66A9F3;
  --black: #000;
  --gray: #999999;
  --gray-100: #F9F9F9;
  --gray-200: #DFDFDF;
  --gray-300: #D9D9D9;
  --gray-300: #D9D9D9;
  --gray-500: #757575;
  --green: #23A256;
  --green-100: #5BD548;
  --green-200: #9DE2AF;
  --green-300:#E7FDED;
  --white: #fff;

  --font-family: 'Roboto', sans-serif;
}

$breakpoint-xs: 320px; // mobile
$breakpoint-sm: 576px; // tablets
$breakpoint-md: 768px; // laptop
$breakpoint-lg: 992px; // large screens
$breakpoint-xl: 1200px; // extralarge screens

$breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl
);

$colors: (
  orange: var(--orange),
  red: var(--red),
  warn: var(--warn),
  info: var(--info),
  black: var(--black),
  gray: var(--gray),
  gray-100: var(--gray-100),
  gray-200: var(--gray-200),
  gray-300: var(--gray-300),
  gray-500: var(--gray-500),
  green: var(--green),
  green-100: var(--green-100),
  white: var(--white)
);

$fonts: (
  xs: 0.75rem,
  sm: 0.875rem,
  md: 1rem,
  lg: 1.25rem,
  xl: 1.5rem,
  xxl: 2rem
);
