// Generating spacing classes:
$spacing-sizes: 8;
$step: 0.5rem;

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;

  .m-#{$i} {
    margin: $value !important;
  }

  .p-#{$i} {
    padding: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "mt-#{$i}";

  .#{$class-name} {
    margin-top: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "mr-#{$i}";

  .#{$class-name} {
    margin-right: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "mb-#{$i}";

  .#{$class-name} {
    margin-bottom: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "ml-#{$i}";

  .#{$class-name} {
    margin-left: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "pt-#{$i}";

  .#{$class-name} {
    padding-top: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "pr-#{$i}";

  .#{$class-name} {
    padding-right: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "pb-#{$i}";

  .#{$class-name} {
    padding-bottom: $value !important;
  }
}

@for $i from 0 through $spacing-sizes {
  $value: $i * $step;
  $class-name: "pl-#{$i}";

  .#{$class-name} {
    padding-left: $value !important;
  }
}

@each $breakpoint, $size in $breakpoints {
  @for $i from 0 through $spacing-sizes {
    $value: $i * $step;

    .m-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        margin: $value !important;
      }
    }

    .p-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        padding: $value !important;
      }
    }

    .mt-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        margin-top: $value !important;
      }
    }

    .mr-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        margin-right: $value !important;
      }
    }

    .mb-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        margin-bottom: $value !important;
      }
    }

    .ml-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        margin-left: $value !important;
      }
    }

    .pt-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        padding-top: $value !important;
      }
    }

    .pr-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        padding-right: $value !important;
      }
    }

    .pb-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        padding-bottom: $value !important;
      }
    }

    .pl-#{$breakpoint}-#{$i} {
      @media (min-width: #{$size}) {
        padding-left: $value !important;
      }
    }
  }
}

// Generating classes for backgrounds, text color based on $colors in _variables.scss:
@each $color-name, $color-value in $colors {
  .bg-#{$color-name} {
    background-color: $color-value !important;
  }

  .color-#{$color-name} {
    color: $color-value !important;
  }
}

// Generating font weight classes
@for $weight from 100 through 600 {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

// Font sizes generate:
@each $font, $font-size in $fonts {
  .font-#{$font} {
    font-size: $font-size !important;
  }
}

$display-classes: (
  none: none,
  inline: inline,
  block: block,
  inline-block: inline-block,
  flex: flex,
);

$position-classes: (
  relative: relative,
  absolute: absolute,
  fixed: fixed,
);

$flex-direction-classes: (
  flex-row: row,
  flex-column: column,
);

$align-classes: (
  align-start: start,
  align-end: end,
  align-center: center
);

$justify-content-classes: (
  justify-start: flex-start,
  justify-end: flex-end,
  justify-center: center,
  justify-between: space-between,
  justify-around: space-around
);

@mixin generate-classes($class-map, $property) {
  @each $class, $value in $class-map {
    @each $breakpoint, $size in $breakpoints {
      $media-query: if($size == 0, 'min-width: 0', "min-width: #{$size}");

      .#{$class}-#{$breakpoint} {
        @media screen and (#{$media-query}) {
          #{$property}: #{$value} !important;
        }
      }
    }

    .#{$class} {
      #{$property}: #{$value};
    }
  }
}

@include generate-classes($display-classes, display);
@include generate-classes($position-classes, position);
@include generate-classes($flex-direction-classes, flex-direction);
@include generate-classes($align-classes, align-items);
@include generate-classes($justify-content-classes, justify-content);
