.faq {
    padding-left: 281px;
    padding-top: 80px;
    padding-bottom: 60px;
    width: 100%;

    @media screen and (max-width: 1580px) {
        padding-left: 224px;
    }

    @media screen and (max-width: 768px) {
        padding-top: 48px;
        padding-left: 0;
    }

    &-container {
        width: 100%;
        max-width: 984px;
        margin: 56px auto 0 auto;
        gap: 64px;
        
        @media screen and (max-width: 1440px) {
            padding: 20px 50px 50px;
            margin: 0 auto;
        }

        @media screen and (max-width: 580px) {
            padding: 30px;
        }
        
        #border-top {
            border-top: 1px solid #d9d9d9;
        }

        #border-bottom {
            border-bottom: 1px solid #d9d9d9;
        }

        &-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;
            
            &:first-child {
                margin-bottom: 45px;
            }

            &-title {
                color: #000;
                font-family: Roboto;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;

                &.__secondary {
                    font-size: 32px;
                    margin-bottom: 20px;
                }
            }

            &-input {
                padding: 18px 36px 18px 59px;
                width: 100%;
                border-radius: 40px;
                border: 1px solid #e2e2e2;
                background: #fff;
                color: #999;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &-container {
                    position: relative;

                    > svg {
                        position: absolute;
                        left: 24px;
                        top: 53%;
                        transform: translateY(-50%);
                    }
                }
            }

            &-block {
                display: flex;
                padding: 24px 0;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                
                @media screen and (max-width: 580px) {
                    display: block;
                }

                &:first-of-type {
                    padding-top: 25px;
                }

                &-element {
                    max-width: 320px;
                    width: 100%;

                    @media screen and (max-width: 580px) {
                        max-width: 100%;
                    }
    
                    h3 {
                        color: #000;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        padding-right: 30px;
                        margin-bottom: 20px;
                    }

                    p {
                        color: #999;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                &-buttons {
                    display: flex;
                    gap: 20px;
                    padding-top: 40px;
                    
                    @media screen and (max-width: 767px) {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .faq-button {
        padding: 18px 20px 18px 25px;
        background-color: #fff;
        border: 1px solid #E2E2E2;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        width: 320px;

        svg {
            margin-right: 20px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}