.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 281px;
  height: 100vh;
  padding: 24px 35px 0;
  background-color: #fff;
  position: fixed;
  z-index: 10;

  gap: 42px;

  border-right: 1px solid #e2e2e2;

  // @media screen and (max-width: 1580px) {
  @media screen and (max-width: 1750px) {
    width: 224px;

    padding: 18px 16px 48px;

    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  .logo-container {
    align-self: center;

    img {
      width: 210px;

      @media screen and (max-width: 1580px) {
        width: 180px;
      }
    }
  }

  &-container {
    flex-grow: 2;

    .navigation-list {
      list-style: none;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      gap: 24px;
      width: 100%;
      padding: 0;

      @media screen and (max-width: 1580px) {
        gap: 16px;
      }

      > li {
        a,
        a:visited {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          gap: 16px;
          height: 48px;
          padding: 10px;
          color: #000;
          text-decoration: none;

          @media screen and (max-width: 1580px) {
            padding: 13px 24px;
          }

          svg,
          img {
            width: 22px;
            height: 22px;
          }
        }
      }

      > li {
        width: 100%;
      }

      > li:hover {
        background-color: #f7f7f7;
        border-radius: 40px;

        // svg {
        //     width: 22px;
        // }
      }

      > li.active {
        background-color: #fef1ee;
        border-radius: 40px;

        > a {
          color: #e74720;

          > svg {
            fill: #e74720;
          }
        }
      }
    }
  }

  .language {
    font-style: normal;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 0.885rem;
  }
}
@media screen and (max-width: 1100px) {
  .hide {
    display: none;
  }
}
