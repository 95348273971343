.profile {
    display: flex;
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;

    &-container {
        width: 100%;
        max-width: 1560px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 72px;
        padding: 48px;
        background-color: #fff;
        margin: 120px 24px 0 310px;

        @media screen and (max-width: 1580px) {
            margin: 96px 24px 0 248px;
            padding: 32px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 32px 20px 80px 20px;
            margin: 96px 0 0 0;
            gap: 47px;
        }

        &-main {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media screen and (max-width: 768px) {
                gap: 14px;
            }

            &-title {
                font-size: 24px;

                @media screen and (max-width: 1580px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }

            &-block {
                display: flex;
                padding-top: 20px;
                border-top: 1px solid #d9d9d9;

                @media screen and (max-width: 1100px) {
                    display: block;
                }

                @media screen and (max-width: 768px) {
                    display: block;
                    height: auto;
                    padding-top: 18px;
                    border-top: 1px solid #d9d9d9;
                }

                &-photo {
                    display: flex;
                    background: #f9f9f9;
                    width: 392px;
                    justify-content: center;
                    align-items: center;
                    gap: 8%;

                    @media screen and (max-width: 1100px) {
                        width: auto;
                        margin-bottom: 20px;
                        padding: 24px;
                    }
                    
                    @media screen and (max-width: 768px) {
                        gap: 14px;
                        padding: 24px 31px 23px 21px;
                        height: auto;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 8px;
                    }

                    >img {
                        width: 100px;
                        height: 100px;
                        border-radius: 100px;
                        object-fit: cover;

                        @media screen and (max-width: 768px) {
                            height: 80px;
                            width: 80px;
                            margin-top: 7px;
                        }
                    }

                    &-upload {
                        display: flex;
                        flex-direction: column;

                        &-title {
                            font-size: 20px;

                            @media screen and (max-width: 768px) {
                                font-size: 16px;
                            }
                        }

                        &-buttons {
                            margin-top: 16px;
                            margin-bottom: 8px;
                            display: flex;
                            align-items: center;
                            gap: 16px;

                            @media screen and (max-width: 768px) {
                                margin-top: 10px;
                                margin-bottom: 10px;
                                gap: 16px;
                            }

                            .button {
                                font-size: 14px;
                                cursor: pointer;

                                @media screen and (max-width: 768px) {
                                    font-size: 16px;
                                    padding: 7px 29px;
                                    letter-spacing: -0.7px;
                                }
                            }

                            >img {
                                width: 16px;
                                height: 16px;

                                @media screen and (max-width: 768px) {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }

                        &-helper {
                            font-size: 14px;
                            color: #999;

                            @media screen and (max-width: 768px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding-left: 20px;
                    flex-grow: 1;

                    @media screen and (max-width: 1580px) {
                        padding-left: 17px;
                    }

                    @media screen and (max-width: 1100px) {
                        padding-left: 0;
                    }

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        gap: 8px;
                    }

                    &-element {
                        background-color: #f9f9f9;
                        padding: 14px 40px;
                        height: 80px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        justify-content: space-between;

                        @media screen and (max-width: 768px) {
                            padding: 16px;
                            height: 80px;
                            font-size: 12px;
                            justify-content: space-between;
                        }

                        &-title {
                            flex-grow: 2;
                            @media screen and (max-width: 768px) {
                                font-size: 14px;
                            }
                        }

                        &-value {
                            margin-right: 40px;
                            font-weight: 400;
                            background-color: #f9f9f9;
                            border: none;
                            outline: none;
                            text-align: right;
                            font-size: 16px;
                            font-family: 'Roboto', sans-serif;
                            display: none;

                            @media screen and (max-width: 768px) {
                                display: block;
                                font-size: 16px;
                                margin-top: 6px;
                            }
                        }

                        &-button {
                            font-size: 14px;
                            padding-left: 40px;
                            border-left: 1px solid #d9d9d9;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            gap: 12px;

                            @media screen and (max-width: 768px) {
                                border-left: none;
                                font-size: 16px;
                                padding-left: 0;
                                gap: 8px;
                            }

                            >img {
                                width: 16px;
                                height: 16px;

                                @media screen and (max-width: 768px) {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-security {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media screen and (max-width: 768px) {
                gap: 14px;
            }

            &-title {
                font-size: 24px;

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }

            &-block {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding-top: 20px;
                border-top: 1px solid #d9d9d9;

                @media screen and (max-width: 768px) {
                    gap: 8px;
                    // height: 172px;
                    padding-top: 18px;
                    border-top: 1px solid #d9d9d9;
                }

                &-element {
                    background-color: #f9f9f9;
                    padding: 14px 40px;
                    height: 80px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 16px;

                    @media screen and (max-width: 768px) {
                        padding: 16px;
                        height: 80px;
                        font-size: 14px;
                    }

                    &-title {
                        flex-grow: 2;
                    }

                    &-value {
                        margin-right: 40px;
                        font-weight: 200;

                        @media screen and (max-width: 768px) {
                            margin-right: 0;
                            display: none;
                        }
                    }

                    &-button {
                        font-size: 14px;
                        padding-left: 40px;
                        border-left: 1px solid #d9d9d9;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        gap: 12px;

                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                            padding-left: 0;
                            border-left: none;
                            gap: 8px;
                        }

                        >img {
                            width: 16px;
                            height: 16px;

                            @media screen and (max-width: 768px) {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }

        &-accounts {
            display: flex;
            flex-direction: column;
            gap: 1.042vw;

            @media screen and (max-width: 768px) {
                gap: 14px;
            }

            &-title {
                font-size: 1.25vw;

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }

            &-block {
                display: flex;
                flex-direction: column;
                gap: 1.042vw;
                height: 9.375vw;
                padding-top: 1.042vw;
                border-top: 0.052vw solid #d9d9d9;

                @media screen and (max-width: 768px) {
                    gap: 8px;
                    height: 156px;
                    padding-top: 14px;
                    border-top: 1px solid #d9d9d9;
                }

                &-element {
                    background-color: #f9f9f9;
                    padding: 0.729vw 2.083vw;
                    height: 4.167vw;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 0.833vw;

                    @media screen and (max-width: 768px) {
                        padding: 14px 20px;
                        height: 52px;
                        font-size: 12px;
                    }

                    &-title {
                        flex-grow: 2;
                        display: flex;
                        align-items: center;
                        gap: 0.729vw;

                        @media screen and (max-width: 768px) {
                            gap: 14px;
                        }

                        >img {
                            height: 1.667vw;
                            width: auto;

                            @media screen and (max-width: 768px) {
                                height: 24px;
                            }
                        }
                    }

                    &-button {
                        font-size: 0.729vw;
                        padding-left: 2.083vw;
                        border-left: 0.052vw solid #d9d9d9;
                        display: flex;
                        align-items: center;
                        gap: 0.625vw;

                        @media screen and (max-width: 768px) {
                            font-size: 12px;
                            padding-left: 0;
                            border-left: none;
                            gap: 8px;
                        }

                        >img {
                            width: 0.833vw;
                            height: 0.833vw;

                            @media screen and (max-width: 768px) {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }
            }
        }

        &-methods {
            display: flex;
            flex-direction: column;
            gap: 1.042vw;

            @media screen and (max-width: 768px) {
                gap: 14px;
            }

            &-title {
                font-size: 1.25vw;

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }

            &-block {
                display: flex;
                flex-direction: column;
                gap: 1.042vw;
                height: 9.375vw;
                padding-top: 1.042vw;
                border-top: 0.052vw solid #d9d9d9;

                @media screen and (max-width: 768px) {
                    gap: 8px;
                    height: 156px;
                    padding-top: 14px;
                    border-top: 1px solid #d9d9d9;
                }

                &-element {
                    background-color: #f9f9f9;
                    padding: 0.729vw 2.083vw;
                    height: 4.167vw;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 0.833vw;

                    @media screen and (max-width: 768px) {
                        padding: 14px 20px;
                        height: 52px;
                        font-size: 12px;
                    }

                    &-title {
                        flex-grow: 2;
                        display: flex;
                        align-items: center;
                        gap: 0.729vw;

                        @media screen and (max-width: 768px) {
                            gap: 12px;
                        }

                        >img {
                            height: 1.667vw;
                            width: auto;

                            @media screen and (max-width: 768px) {
                                height: 22px;
                            }
                        }
                    }

                    &-button {
                        font-size: 0.729vw;
                        padding-left: 2.083vw;
                        border-left: 0.052vw solid #d9d9d9;
                        display: flex;
                        align-items: center;
                        gap: 0.625vw;

                        @media screen and (max-width: 768px) {
                            font-size: 12px;
                            padding-left: 0;
                            border-left: none;
                            gap: 0;
                        }

                        >img {
                            width: 0.833vw;
                            height: 0.833vw;
                        }
                    }
                }
            }
        }
    }
}

.remove-avatar {
    cursor: pointer;
}