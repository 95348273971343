.table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    // margin-bottom: 1.042vw;
    margin-bottom: 20px;

    &-title {
      font-size: 24px;

      @media screen and (max-width: 1580px) {
        font-size: 20px;
      }

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-bottom: 0;

        .title-img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .table-header-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      gap: 16px;

      .button {
        font-size: 14px;
        padding: 13px 32px;
      }
    }
  }

  &-content {
    width: 100%;

    > table {
      text-align: left;

      border-top: 1px solid #d9d9d9;

      padding: 20px 0;

      th,
      td {
        @media screen and (max-width: 768px) {
          padding-left: 0;
        }
      }
    }

    &-button {
      border-left: 1px solid black;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      gap: 32px;

      > img {
        width: 20px;
      }
    }
  }
}
