.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 280px);
  position: fixed;
  height: 80px;
  background-color: #fff;
  z-index: 10;
  padding: 0 40px;
  margin-left: 280px;
  border-bottom: 1px solid #e2e2e2;

  // @media screen and (max-width: 1580px) {
  @media screen and (max-width: 1750px) {
    height: 72px;
    width: calc(100% - 224px);
    margin-left: 224px;
  }

  @media screen and (max-width: 768px) {
    height: 64px;
    padding: 8px 20px;
    border: none;
    width: 100%;
    margin-left: 0;
  }

  &-item {
    display: flex;
  }

  .header-block {
    display: flex;
    align-items: center;

    gap: 40px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .logo-container-mob {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        // width: 32px;
        // height: 32px;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }

  .title {
    > h3 {
      font-size: 28px;
      text-align: left;

      @media screen and (max-width: 1580px) {
        font-size: 24px;
      }
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .buttons-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;

    .button {
      min-width: 120px;
      padding: 13px 30px;
      font-size: 16px;
    }
  }

  .notification {
    width: 44px;
    height: 44px;
    border: 1px solid #e2e2e2;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      height: 18px;
      width: 18px;
    }

    @media screen and (max-width: 768px) {
      border: none;
      > img {
        // height: 24px;
        height: auto;
        width: 23px;
      }
    }
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 1.25vw;
      height: 1.25vw;
    }

    @media screen and (max-width: 768px) {
      > img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .user {
    width: 44px;
    height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
    cursor: pointer;

    border-radius: 100%;
    background-color: #eee;

    &-modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      position: absolute;
      right: 14px;
      top: 71px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      width: 200px;

      &-element {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;
        padding: 1.25rem;

        &:hover {
          background-color: #F7F7F7;
        }

        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
  
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        p {
          color: #000;

          /* M/M-menu */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          margin: 0;
        }

        svg,
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .header-block-mob {
    display: none;
    gap: 0.729rem;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 7px;
    }

    &-buy {
      padding: 10px 20px 11px;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
  }
}
